import { useCallback } from 'react';

import { BookDefinition } from '@/components/common/Book';
import { FEATURE_FLAG_KEYS, useFeatureFlagValueByKey } from '@/components/common/FeatureFlag';
import { GridOrderedBookItemRenderer, GridOrderedBookPreset } from '@/components/genreHome/common/GridOrderedBook';
import { GridOrderedLayoutSection } from '@/components/genreHome/common/GridOrderedLayoutSection';
import { SectionLayout } from '@/components/genreHome/common/SectionLayout';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { BookViewItem, SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import type { ReadingBookItem, SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

import { RankingBookItemRenderer, RankingBookPreset, RankingLayoutSection } from '../../common/RankingBook';

export interface ReadingBookProps {
  section: SectionByLayout<SectionLayoutType.ReadingBook>;
}

export const ReadingBook = ({ section }: ReadingBookProps): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();

  // 장르홈 랭킹섹션 UI 실험
  const isExperimentTarget = useFeatureFlagValueByKey()(FEATURE_FLAG_KEYS.DISCOVERY_EXP_RANKING_SECTION_20231201);
  const bookPreset = isExperimentTarget ? RankingBookPreset : GridOrderedBookPreset;
  const ItemRenderer = isExperimentTarget ? RankingBookItemRenderer : GridOrderedBookItemRenderer;
  const render = useCallback(
    ({ item, index }: { item: BookViewItem<ReadingBookItem>; index: number }) => (
      <ItemRenderer key={index} item={item} index={index} />
    ),
    [ItemRenderer],
  );
  const sectionRendered = isExperimentTarget ? (
    <RankingLayoutSection items={section.items} renderItem={render} />
  ) : (
    <GridOrderedLayoutSection items={section.items} renderItem={render} />
  );

  return (
    <SectionLayout title={section.title} link={section.more_details?.path || undefined} horizontal={false}>
      <BookDefinition presets={bookPreset} trackingData={sectionTrackingData.bookDefinition}>
        {sectionRendered}
      </BookDefinition>
    </SectionLayout>
  );
};
